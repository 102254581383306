import * as React from 'react';
import {ChangeEvent, useState} from 'react';
import {faChevronCircleRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Button from '../../ui/Buttons/Button';
import ImpressionTracker from '../../analytics/impression-tracker/ImpressionTracker';
import {Badge} from '../../ui/Badges/Badge';
import {GaItemListName} from '../../../client/ga/ga-ecommerce.functions';
import {gaLegacySiteSearch} from '../../../client/ga/ga-legacy.functions';
import {Input} from '../../ui/forms/Input/Input';
import {Item} from '../../items/item.class';
import {OrderItemsWorkflow} from '../../../client/order-items/order-items.workflow';
import {OrdersService} from '../../../client/orders/orders.service';
import {Price} from '../../pricing/Price';
import {ProductSuggestion} from '../../search/search.types';
import {pushGaEvent} from '../../../client/ga/ga.functions';
import {SignalsService} from '../../../client/search/signals/signals.service';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';

interface SearchOverlayResultsProps {
    addToOrderComponentName: string;
    closeOverlay: () => void;
    currentProductsQueryID: string;
    index: number;
    productSuggestion: ProductSuggestion;
    recordSearchTerm: () => void;
    searchString: string;
    suggestionType: `partNumbers` | `products`;
    user: User;
}

export const SearchOverlayProductSuggestion = ({
    addToOrderComponentName,
    closeOverlay,
    currentProductsQueryID,
    index,
    productSuggestion,
    recordSearchTerm,
    searchString,
    suggestionType,
    user,
}: SearchOverlayResultsProps) => {
    const [addToOrderQty, setAddToOrderQty] = useState(`1`);
    const componentName = `SearchOverlayProductSuggestion`;
    const gaItemListName: GaItemListName = suggestionType === `partNumbers` ? `search_overlay_exact_match` : `search_overlay_products`;
    const orderItemsWorkflow: OrderItemsWorkflow = useService(`orderItemsWorkflow`);
    const ordersService: OrdersService = useService(`ordersService`);
    const signalsService: SignalsService = useService(`signalsService`);

    /**
     * Adds provided itemNum to order
     * @param itemNum
     */
    const addToOrder = (itemNum: string) => {
        orderItemsWorkflow.addToOrderModal(
            [
                {
                    gaEcommerce: {item_list_name: gaItemListName},
                    item: itemNum,
                    unitsOrdered: parseInt(addToOrderQty),
                },
            ],
            addToOrderComponentName,
            true,
            closeOverlay,
        );
    };

    /**
     * TBD
     */
    const handleClick = () => {
        recordSearchTerm();
        gaLegacySiteSearch(searchString, `typeahead_search`, true);
        signalsService
            .sendSignals([
                {
                    params: {
                        collection: `ImperialItems`,
                        docId: productSuggestion.id,
                        fusion_query_id: currentProductsQueryID,
                        res_pos: index + 1,
                        source: gaItemListName,
                    },
                    type: `click`,
                },
            ])
            .catch((sendSignalsErr) => {
                // fail silently, most likely a navigation before signals finish saving
                // eslint-disable-next-line no-console
                console.log(`Error sending fusion signals`, sendSignalsErr);
            });
        pushGaEvent(`ga4_search_overlay_click`, {
            click_type: `search_overlay_exact_match`,
            search_input: searchString,
            selection_id: productSuggestion.id,
            selection_name: productSuggestion.name,
        });
    };

    /**
     * Template
     */
    return (
        <>
            {index < 6 && (
                <ImpressionTracker
                    as="div"
                    classes="tw-block lg:tw-hidden tw-border-t tw-w-full"
                    data={{
                        dimension16: ordersService?.currentOrderNumber,
                        index,
                        item_id: productSuggestion.id,
                        item_list_name: gaItemListName,
                    }}
                >
                    <div
                        className="tw-place-content-center tw-items-center tw-flex tw-no-underline tw-text-black tw-cursor-pointer"
                        onClick={() => {
                            handleClick();
                            location.assign(`/item/${productSuggestion.id}`);
                        }}
                    >
                        <img
                            alt={productSuggestion.name}
                            className="tw-p-4"
                            height="75"
                            src={`/ProductImageThumbs75${productSuggestion.imagePath || '/noimage.png'}`}
                            width="75"
                        />
                        <div className="tw-my-2 tw-flex-grow">
                            {productSuggestion.prevPurchased && (
                                <Badge
                                    className="!tw-py-0 !tw-px-1"
                                    variant="success"
                                >
                                    Purchased
                                </Badge>
                            )}
                            <div>{Item.prettyItem(productSuggestion.id || ``)}</div>
                            <div className="tw-text-base tw-mb-2 tw-font-normal tw-leading-[1.2]">{productSuggestion.name}</div>
                            {!(productSuggestion.isGDSP && productSuggestion.discontinued) && (
                                <Price
                                    alias={componentName}
                                    className="tw-text-sm tw-font-normal tw-leading-[1.3]"
                                    item={productSuggestion}
                                    key={`price_${suggestionType}_${index}`}
                                    onModalDisplay={() => closeOverlay()}
                                    user={user}
                                />
                            )}
                        </div>
                    </div>
                </ImpressionTracker>
            )}
            <ImpressionTracker
                classes="tw-mx-auto lg:tw-mx-0 tw-w-full tw-my-0 tw-items-center tw-px-0 tw-flex tw-flex-grow tw-relative tw-min-w-max tw-mb-4 tw-hidden lg:tw-flex"
                data={{
                    dimension16: ordersService?.currentOrderNumber,
                    index,
                    item_id: productSuggestion.id,
                    item_list_name: gaItemListName,
                }}
                trackClicks={false}
            >
                <div className="tw-relative tw-flex tw-flex-col tw-min-w-0 tw-bg-white tw-border-t tw-rounded-sm tw-h-full tw-w-full">
                    <div className="tw-p-4 tw-flex tw-items-start tw-flex-col">
                        <div className="tw-mb-auto">
                            <ImpressionTracker
                                as="span"
                                data={{
                                    dimension16: ordersService?.currentOrderNumber,
                                    index,
                                    item_id: productSuggestion.id,
                                    item_list_name: gaItemListName,
                                }}
                                trackImpressions={false}
                            >
                                <a
                                    className="tw-text-center tw-pt-4 tw-block"
                                    href={`/item/${productSuggestion.id}`}
                                    onClick={handleClick}
                                >
                                    <div className="tw-text-center tw-mb-4">
                                        <img
                                            alt={productSuggestion.name}
                                            height="75"
                                            src={`/ProductImageThumbs75${productSuggestion.imagePath || '/noimage.png'}`}
                                            width="75"
                                        />
                                    </div>
                                    <div className="tw-text-left">
                                        <div className="tw-text-sm tw-font-normal tw-leading-[1.3] tw-text-gray-650">
                                            {Item.prettyItem(productSuggestion.id || ``)}
                                        </div>
                                        {productSuggestion.prevPurchased && (
                                            <Badge
                                                className="!tw-py-1 !tw-px-1.5"
                                                variant="success"
                                            >
                                                Purchased
                                            </Badge>
                                        )}
                                        <div className="tw-text-base tw-mb-2 tw-font-normal tw-leading-[1.2]">{productSuggestion.name}</div>
                                    </div>
                                </a>
                            </ImpressionTracker>
                        </div>
                        {!(productSuggestion.discontinued && (productSuggestion.isGDSP || productSuggestion.inStock === false)) && (
                            <Price
                                alias={componentName}
                                className="tw-text-sm tw-font-normal tw-leading-[1.3] tw-text-gray-650 tw-mt-1"
                                item={productSuggestion}
                                key={`price_${suggestionType}_${index}`}
                                onModalDisplay={() => closeOverlay()}
                                user={user}
                            />
                        )}
                        <div className="tw-flex tw-mt-4">
                            <Input
                                className="!tw-w-[52px] !tw-h-[38px]"
                                clearMargin={true}
                                name="addToOrderQty"
                                numbersOnly={true}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => setAddToOrderQty(event.target.value)}
                                value={addToOrderQty}
                            />
                            <Button
                                className="!tw-ml-2"
                                onClick={() => addToOrder(productSuggestion.id)}
                                size="sm"
                                variant="primary"
                            >
                                Add to Order
                            </Button>
                        </div>
                    </div>
                    <div className="tw-py-3 tw-px-5 tw-bg-white tw-pt-0">
                        <div className="tw-mt-1 tw-text-sm tw-font-normal tw-leading-[1.3] tw-text-gray-650">
                            <a
                                className="tw-text-sm tw-whitespace-nowrap"
                                onClick={() => {
                                    gaLegacySiteSearch(searchString, `typeahead_search`, true);
                                    pushGaEvent(`ga4_search_overlay_click`, {
                                        click_type: `search_overlay_similar_products`,
                                        search_input: searchString,
                                        selection_id: productSuggestion.id,
                                        selection_name: productSuggestion.name,
                                    });
                                }}
                                href={`/browse/${productSuggestion.categoryPath}`}
                            >
                                <FontAwesomeIcon
                                    className="tw-text-gray-200 tw-ml-[-3px]"
                                    icon={faChevronCircleRight}
                                />{' '}
                                View Similar Products
                            </a>
                        </div>
                    </div>
                </div>
            </ImpressionTracker>
        </>
    );
};
