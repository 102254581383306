import * as React from 'react';

import ImpressionTracker from '../../../analytics/impression-tracker/ImpressionTracker';
import {GaItemListName} from '../../../../client/ga/ga-ecommerce.functions';
import {OrdersService} from '../../../../client/orders/orders.service';
import {Price} from '../../../pricing/Price';
import {TopProductsItem} from './TopProductsItem';
import {TopProduct} from '../../../items/item.class';
import {User} from '../../../users/user.class';
import {useService} from '../../../react/ServiceContext';

interface TopProductsSectionProps {
    closeMegaMenu: () => void;
    gaItemListName: GaItemListName;
    isQuickAdd?: boolean;
    setAddToOrderError: React.Dispatch<React.SetStateAction<string>>;
    topProducts: TopProduct[];
    user: User;
}

export const TopProductsSection = ({
    closeMegaMenu,
    gaItemListName,
    isQuickAdd,
    setAddToOrderError,
    topProducts,
    user,
}: TopProductsSectionProps) => {
    const componentName = `TopProductsSection`;
    const ordersService: OrdersService = useService(`ordersService`);

    /**
     * Template
     */
    return (
        <div className="col-6">
            <ul className={`${isQuickAdd && 'tw-list-none tw-m-0'}`}>
                {topProducts.map((topProduct, index) => (
                    <li
                        className={`${isQuickAdd && 'tw-w-full tw-float-left tw-mr-[26px] tw-pr-3.5 tw-mb-5'}`}
                        key={topProduct.id}
                    >
                        <div className={`item-thumb ${isQuickAdd && 'tw-mr-2.5 tw-float-left'}`}>
                            <ImpressionTracker
                                as="span"
                                data={{
                                    dimension16: ordersService?.currentOrderNumber,
                                    index: index + 1,
                                    item_id: topProduct.id,
                                    item_list_name: gaItemListName,
                                }}
                            >
                                <a href={`/item/${topProduct.id}`}>
                                    <img
                                        alt={topProduct.name}
                                        height="64"
                                        src={`/ProductImageThumbs75${topProduct.imagePath || '/noimage.png'}`}
                                        width="64"
                                    />
                                </a>
                            </ImpressionTracker>
                        </div>
                        <div className={`item ${isQuickAdd && 'tw-float-left tw-w-[calc(100%-74px)] tw-pb-2.5'}`}>
                            <div className={`item-body ${isQuickAdd && 'tw-float-left'}`}>
                                <a
                                    className={`text-capitalize product-description ${isQuickAdd && 'tw-max-w-[204px] tw-uppercase'}`}
                                    href={`/item/${topProduct.id}`}
                                >
                                    {topProduct.name.toLowerCase()}
                                </a>
                                <div className="list__itemPriceBlock">
                                    <div className="pt-2">Pkg Qty: {topProduct.pkgQty}</div>
                                    <div className="pt-2">
                                        <Price
                                            alias={componentName}
                                            item={topProduct}
                                            user={user}
                                        />
                                    </div>
                                </div>
                            </div>
                            <TopProductsItem
                                closeMegaMenu={closeMegaMenu}
                                gaItemListName={gaItemListName}
                                isQuickAdd={isQuickAdd}
                                itemNum={topProduct.id}
                                setAddToOrderError={setAddToOrderError}
                            />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};
