/* eslint-disable max-len */
import * as React from 'react';
import * as yup from 'yup';
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormProvider, useForm} from 'react-hook-form';
import {useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';

import Button from '../../ui/Buttons/Button';
import ToolTip from '../../ui/Tooltips';
import {Alerts} from '../../ui/Alerts/Alerts';
import {AuthenticationService} from '../../../client/authentication/authentication.service';
import {CheckBox} from '../../ui/forms/CheckBox/CheckBox';
import {ContentModal} from '../../ui/modals/ContentModal';
import {ImpError} from '../../../client/imp-error/imp-error.class';
import {Input} from '../../ui/forms/Input/Input';
import {OurBenefits} from '../../account/our-benefits/OurBenefits';
import {SessionStorageService} from '../../../client/session-storage/session-storage.service';
import {UserStateService} from '../../../client/users/user-state.service';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';
import {validateLoginName, validatePassword} from '../../tools/yup-validators.functions';

interface LoginModalFormValues {
    login: string;
    password: string;
    stayauthed: boolean;
}

interface LoginModalProps {
    onClose: () => void;
    reauthenticate: boolean;
    returnUrl: string;
    show: boolean;
    user: User;
}

export const LoginModal = ({onClose, returnUrl, reauthenticate, show, user}: LoginModalProps) => {
    const [errorMessage, setErrorMessage] = useState(``);
    const [showPassword, setShowPassword] = useState(false);
    const authenticationService: AuthenticationService = useService(`authenticationService`);
    const loginFormValidation = yup.object().shape({login: validateLoginName(), password: validatePassword()});
    const sessionStorageService: SessionStorageService = useService(`sessionStorageService`);
    const userStateService: UserStateService = useService(`userStateService`);
    const useFormReturn = useForm<LoginModalFormValues>({
        defaultValues: {login: user.loginName ? user.loginName : ``, password: ``, stayauthed: true},
        resolver: yupResolver(loginFormValidation),
    });

    // Clear sessionStorage if not re-authenticating
    useEffect(() => {
        if (!reauthenticate) {
            sessionStorageService.clear();
        }
        // Only run once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Set focus on "login" field
    useEffect(() => {
        if (reauthenticate) {
            useFormReturn.setFocus(`password`);
        } else {
            useFormReturn.setFocus(`login`);
        }
    }, [reauthenticate, useFormReturn]);

    /**
     * Cancels any pending workflow when the modal is closed
     */
    const closeModal = () => {
        userStateService.clearPendingAction();
        onClose();
    };

    /**
     * Logs the user in
     * @param loginModalFormValues
     */
    const submitLoginForm = (loginModalFormValues: LoginModalFormValues) => {
        setErrorMessage(``);
        authenticationService
            .authenticateUser(loginModalFormValues.login, loginModalFormValues.password, loginModalFormValues.stayauthed, reauthenticate)
            .then((authenticateUserRes) => {
                if (!reauthenticate) {
                    sessionStorageService.setItem(`recordGaLogin`, `login_modal`);
                }

                // Send multi-account users to /pick-account
                if (authenticateUserRes.pickAccount === true) {
                    location.assign(`/pick-account?url=${encodeURIComponent(returnUrl)}`);
                    return;
                }

                // Else redirect to _returnUrl
                location.assign(returnUrl);
            })
            .catch((authenticateUserErr: ImpError) => {
                setErrorMessage(authenticateUserErr.message);
            });
    };

    /**
     * Template
     */
    return (
        <ContentModal
            onClose={closeModal}
            show={show}
            title={reauthenticate ? `Enter Password` : `Log In To Your Account`}
        >
            <Alerts
                message={errorMessage}
                variant="danger"
            />
            {reauthenticate && <p>You are about to access important business information, log in to continue.</p>}
            <FormProvider {...useFormReturn}>
                <form
                    className="tw-mb-6"
                    onSubmit={(event) => {
                        useFormReturn
                            .handleSubmit(submitLoginForm)(event)
                            .catch((handleSubmitErr) => {
                                setErrorMessage(handleSubmitErr.message);
                            });
                    }}
                >
                    {reauthenticate ? (
                        <>
                            <input
                                type="hidden"
                                {...useFormReturn.register(`login`)}
                            />
                            <div className="tw-mb-6 maskPII">Email: {user.loginName}</div>
                        </>
                    ) : (
                        <Input
                            className="maskPII"
                            error={useFormReturn.formState.errors.login?.message}
                            label="Email"
                            name="login"
                            required
                            {...useFormReturn.register(`login`)}
                        />
                    )}
                    <div className="tw-relative tw-font-normal">
                        <Input
                            className="maskPII"
                            error={useFormReturn.formState.errors.password?.message}
                            label="Password"
                            name="password"
                            onShowPassword={(showPass) => {
                                setShowPassword(!showPass);
                            }}
                            required
                            showPass={showPassword}
                            type="password"
                            {...useFormReturn.register(`password`)}
                        />
                    </div>
                    <div className="tw-text-right tw-mb-4">
                        {!reauthenticate && (
                            <>
                                <a
                                    className="tw-mr-2"
                                    href="/create-account?existingAccount=true"
                                    onClick={closeModal}
                                >
                                    Request Web Access
                                </a>{' '}
                                |
                            </>
                        )}
                        <a
                            className="tw-ml-2"
                            href="/forgot-password"
                            onClick={closeModal}
                        >
                            Reset Password
                        </a>
                    </div>
                    {!reauthenticate && (
                        <div className="tw-mb-4">
                            <div className="tw-relative tw-z-index-[1] tw-block tw-min-h-[1.5rem]">
                                <CheckBox
                                    alias="loginSaveChkbox"
                                    defaultChecked={true}
                                    error={useFormReturn?.formState.errors?.stayauthed?.message}
                                    label={
                                        <>
                                            Keep me signed in
                                            <ToolTip
                                                id="loginTooltip"
                                                title="If the 'Keep me signed in' box is checked, you will stay logged in for one year, or until you attempt to carry out a sensitive action, such as accessing business reports. We strongly recommend you don't use this feature on devices and computers you share with other people."
                                            >
                                                <FontAwesomeIcon
                                                    className="tw-text-gray-400 tw-mt-0.5 tw-ml-2"
                                                    icon={faInfoCircle}
                                                />
                                            </ToolTip>
                                        </>
                                    }
                                    name="stayauthed"
                                    {...useFormReturn.register(`stayauthed`)}
                                />
                            </div>
                        </div>
                    )}
                    <Button
                        className="tw-w-full tw-mb-6"
                        type="submit"
                        variant="primary"
                    >
                        Log In
                    </Button>
                    {!reauthenticate && (
                        <Button
                            className="tw-w-full"
                            href={`/create-account?returnUrl=${returnUrl}`}
                            variant="outline-secondary"
                        >
                            Create Account
                        </Button>
                    )}
                </form>
                {!reauthenticate && <OurBenefits creativeSource="Login Modal" />}
            </FormProvider>
        </ContentModal>
    );
};
